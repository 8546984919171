<script setup lang="ts">
import { Button } from '@/components/ui/button'
import AuthLayout from '@/layouts/client/auth.vue'
import { useForm } from '@inertiajs/vue3'
import VueTurnstile from 'vue-turnstile'
import { Loader, AlertCircle } from 'lucide-vue-next'
import FTFormWrapper from '@/components/app/FTFormWrapper.vue'

defineProps<{
  siteKey: string,
  exceptions: Record<string, string>
}>()

defineOptions({
  layout: AuthLayout
})

interface FormErrors {
  email?: string
  password?: string
  verified?: string
  clients?: string
  E_INVALID_CREDENTIALS?: string
}

const form = useForm({
  email: '',
  password: '',
  turnstile: '',
  rememberMe: false,
  errors: {} as FormErrors
})
</script>

<template>
  <AppHead title="Login" description="Client login to Footy Tipping competition management." />
  <FTFormWrapper title="Login">
    <template #subtitle>
      Enter your email below to login to your account
    </template>
    <form class="grid gap-4" @submit.prevent="form.post('/login', { onSuccess: () => { form.password = '' } })">
      <Alert variant="destructive" class="mb-4" v-if="exceptions.E_INVALID_CREDENTIALS">
        <AlertCircle class="h-4 w-4 mr-2" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{{ exceptions.E_INVALID_CREDENTIALS }}</AlertDescription>
      </Alert>
      <Alert variant="destructive" class="mb-4" v-if="form.errors['verified']">
        <AlertCircle class="h-4 w-4 mr-2" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{{ form.errors['verified'] }}</AlertDescription>
      </Alert>
      <Alert variant="destructive" class="mb-4" v-if="form.errors['clients']">
        <AlertCircle class="h-4 w-4 mr-2" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{{ form.errors['clients'] }}</AlertDescription>
      </Alert>
      <FTInput id="email" name="email" label="Email" type="email" v-model="form.email" :error="form.errors.email"
        :disabled="form.processing" placeholder="me@example.com" />
      <div class="grid gap-2">
        <div class="flex items-center">
          <Label for="password">Password</Label>
          <Link href="/client/forgot-password" class="ml-auto inline-block text-sm underline">
          Forgot your password?
          </Link>
        </div>
        <Input id="password" type="password" name="password" v-model="form.password" />
        <Error v-if="form.errors.password">{{ form.errors.password }}</Error>
      </div>
      <div class="flex items-center space-x-2">
        <Checkbox id="remember_me" name="remember_me" v-model="form.rememberMe" />
        <label for="remember_me"
          class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          Remember me
        </label>
      </div>
      <vue-turnstile :site-key="siteKey" v-model="form.turnstile" theme="light" appearance="interaction-only" />
      <Button type="submit" class="w-full" :disabled="form.processing">
        <Loader v-if="form.processing" class="mr-2 w-4 h-4 animate-spin" />
        Login
      </Button>
    </form>
    <div class="mt-4 text-center text-sm">
      Don't have an account?
      <Link href="/register" class="underline">
      Sign up
      </Link>
    </div>
  </FTFormWrapper>
</template>
